import DateBox from "@bizhermit/react-sdk/dist/controls/datebox";
import TsCode from "../basic/ts-code";
import { FC } from "react";

const DateBoxDemoComponent: FC = () => {
  return (
    <>
    <TsCode value={`import DateBox from "@bizhermit/react-sdk/dist/controls/datebox";`} />
    <DateBox defaultValue={new Date()} />
    <TsCode value={code1} />
    <DateBox defaultValue={new Date()} mode="ym" />
    <TsCode value={code2} />
    <DateBox defaultValue={new Date()} mode="y" />
    <TsCode value={code3} />
    </>
  );
};

export default DateBoxDemoComponent;

const code1 = 
`<DateBox defaultValue={new Date()} />`;

const code2 = 
`<DateBox defaultValue={new Date()} mode="ym" />`;

const code3 = 
`<DateBox defaultValue={new Date()} mode="y" />`;
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./utils/reportWebVitals";
import "./base.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MenuBar from "./components/menu-bar";
import IndexPage from "./components/pages";
import ReactSDKPage from "./components/pages/react-sdk";
import { LayoutProvider } from "@bizhermit/react-sdk/dist/layouts/style";
import { useDevice } from "@bizhermit/react-sdk/dist/utils/dom-utils";

const Pages = () => {
  const device = useDevice();
  useEffect(() => {
    if (device.touchable) {
      document.body.style.fontSize = "1.8rem";
    } else {
      document.body.style.removeProperty("fontSize");
    }
  }, [device.touchable]);
  return (
    <LayoutProvider color="system" design="neumorphism">
      <BrowserRouter basename="/">
        <MenuBar>
          <Routes>
            <Route path="/" element={<IndexPage />} />
            <Route path="/react-sdk" element={<ReactSDKPage />} />
          </Routes>
        </MenuBar>
      </BrowserRouter>
    </LayoutProvider>
  );
};

ReactDOM.render(<React.StrictMode><Pages /></React.StrictMode>, document.getElementById("root"));

reportWebVitals();
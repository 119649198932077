import AccordionContainer from "@bizhermit/react-sdk/dist/containers/accordion-container";
import Caption from "@bizhermit/react-sdk/dist/containers/caption";
import FlexBox from "@bizhermit/react-sdk/dist/containers/flexbox";
import Button from "@bizhermit/react-sdk/dist/controls/button";
import { CssPV } from "@bizhermit/react-sdk/dist/layouts/css-var";
import Label from "@bizhermit/react-sdk/dist/texts/label";
import { FC } from "react";
import TsCode from "../basic/ts-code";

const ButtonDemoComponent: FC = () => {
  const asyncFunc = () => {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, 2000);
    });
  }
  return (
    <>
    <TsCode value={`import Button from "@bizhermit/react-sdk/dist/controls/button";`} />
    {CssPV.signalIterator(s => {
      return (
        <Caption key={s} label={`${s}:`} labelWidth={100} style={{ marginTop: 5 }} signal={s}>
          <Button signal={s}>button</Button>
          <Button signal={s} round>round button</Button>
          <Button signal={s} image="download" />
          <Button signal={s} image="upload" round />
          <Button signal={s} image="cloud-download">icon + text</Button>
          <Button signal={s} image="cloud-done" round>icon + text</Button>
          <Button signal={s} image="ok" style={{ width: 200 }}>width: 200</Button>
          <Button signal={s} image="ng" style={{ width: 200 }} round click={async (unlock) => {
            await asyncFunc();
            unlock();
          }}>async func</Button>
        </Caption>
      );
    })}
    <AccordionContainer caption="Code" fitToOuter="fx">
      <FlexBox fitToOuter="fill" style={{ padding: 5 }}>
        <Label type="h3">sync func</Label>
        <TsCode value={code1}></TsCode>
        <Label type="h3">async func</Label>
        <TsCode value={code4}></TsCode>
        <Label type="h3">icon button</Label>
        <TsCode value={code2}></TsCode>
        <Label type="h3">icon only</Label>
        <TsCode value={code3}></TsCode>
      </FlexBox>
    </AccordionContainer>
    </>
  );
};

export default ButtonDemoComponent;

const code1 =
`<Button click={() => {
    console.log("clicked");
}}>button</Button>`;
const code2 =
`<Button image="download" click={() => {
    console.log("download");
}}>download</Button>`;
const code3 =
`<Button image="upload" click={() => {
    console.log("upload");
}} />`;
const code4 = 
`<Button click={async (unlock) => {
    await asyncFunc();
    unlock();
}}>async function</Button>`;
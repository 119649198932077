import ToggleBox from "@bizhermit/react-sdk/dist/controls/togglebox";
import { FC, useState } from "react";
import TsCode from "../basic/ts-code";

const ToggleBoxDemoComponent: FC = () => {
  const [value, setValue] = useState("0");
  return (
    <>
    <TsCode value={`import ToggleBox from "@bizhermit/react-sdk/dist/controls/togglebox";`} />
    <ToggleBox defaultValue={true} />
    <TsCode value={code1} />
    <ToggleBox checkedValue="1" uncheckedValue="0" defaultValue={value} changed={v => setValue(v)} />
    <TsCode value={code2} />
    </>
  );
};

export default ToggleBoxDemoComponent;

const code1 = 
`<ToggleBox defaultValue={true} />`;

const code2 =
`const [value, setValue] = useState("0");

return <ToggleBox checkedValue="1" uncheckedValue="0" defaultValue={value} changed={v => setValue(v)} />;`
import NumericBox from "@bizhermit/react-sdk/dist/controls/numericbox";
import TsCode from "../basic/ts-code";
import { FC } from "react";

const NumericBoxDemoComponent: FC = () => {
  return (
    <>
    <TsCode value={`import NumericBox from "@bizhermit/react-sdk/dist/controls/numericbox";`} />
    <NumericBox defaultValue={1000} />
    <TsCode value={code1} />
    </>
  );
};

export default NumericBoxDemoComponent;

const code1 = 
`<NumericBox defaultValue={1000} />`;
import ClassNameUtils from "@bizhermit/react-sdk/dist/utils/classname-utils";
import { FC, ReactNode, useMemo } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledLinkLabel = styled.div`
display: flex;
flex-flow: row nowrap;
justify-content: flex-start;
align-items: center;
white-space: nowrap;
padding-top: 2px;
&:hover {
  font-weight: bold;
}
`;

type Props = {
  children: ReactNode;
  href: string
  self?: boolean;
  disabled?: boolean;
  className?: string;
};

const Anchor: FC<Props> = ({ children, href, self, disabled, className }) => {
  const props = useMemo(() => {
    if (self === true) return {};
    return {
      target: "_blank",
      rel: "noopener noreferrer",
    };
  }, [self]);
  if (disabled) return <StyledLinkLabel style={{ textDecoration: "underline", cursor: "not-allowed" }} className={className}>{children}</StyledLinkLabel>
  if (href.startsWith("http") || href.startsWith("mailto:")) return <a href={href} {...props}><StyledLinkLabel className={className}>{children}</StyledLinkLabel></a>;
  return <Link to={href}><StyledLinkLabel className={ClassNameUtils.join("bh-anchor", className)}>{children}</StyledLinkLabel></Link>
};

export default Anchor;
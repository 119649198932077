import ArrayUtils from "@bizhermit/basic-utils/dist/array-utils";
import NumberUtils from "@bizhermit/basic-utils/dist/number-utils";
import AccordionContainer from "@bizhermit/react-sdk/dist/containers/accordion-container";
import Row from "@bizhermit/react-sdk/dist/containers/row";
import Button from "@bizhermit/react-sdk/dist/controls/button";
import ListView, { ListViewColumnProps } from "@bizhermit/react-sdk/dist/controls/listview";
import TsCode from "../basic/ts-code";
import { useEffect, useMemo, useState, FC } from "react";

const ListViewDemoComponent: FC = () => {

  const [items, setItems] = useState([]);
  const columns = useMemo(() => {
    return ArrayUtils.generateArray(10, (index) => {
      return {
        name: `col${index}`,
        headerCellLabel: `Col ${index}`,
        fixed: index === 1,
        fill: index === 9,
      } as ListViewColumnProps;
    });
  }, []);

  const generageItems = (length = 100) => {
    setItems(ArrayUtils.generateArray(length, (index) => {
      const value: {[key: string]: any} = {};
      for (let i = 0, il = columns.length; i < il; i++) {
        value[`col${i}`] = `${NumberUtils.format(index * i)}`;
      }
      return value;
    }));
  };

  useEffect(() => {
    generageItems();
  }, []);

  return (
    <>
    <AccordionContainer caption="code" style={{ width: "100%", maxHeight: "50%" }} opened={false}>
      <TsCode value={`import ListView from "@bizhermit/react-sdk/dist/controls/listview";`} />
      <TsCode value={code1} />
    </AccordionContainer>
    <Row>
      <Button click={() => generageItems(0)}>{NumberUtils.format(0)}</Button>
      <Button click={() => generageItems(10)}>{NumberUtils.format(10)}</Button>
      <Button click={() => generageItems(100)}>{NumberUtils.format(100)}</Button>
      <Button click={() => generageItems(1000)}>{NumberUtils.format(1000)}</Button>
      <Button click={() => generageItems(10000)}>{NumberUtils.format(10000)}</Button>
      <Button click={() => generageItems(100000)}>{NumberUtils.format(100000)}</Button>
    </Row>
    <ListView fitToOuter="ffy" columns={columns} value={items} />
    </>
  );
};

export default ListViewDemoComponent;

const code1 =
`const [items, setItems] = useState([]);

const columns = useMemo(() => {
    return ArrayUtils.generateArray(10, (index) => {
        return {
            name: \`col\${index}\`,
            headerCellLabel: \`Col \${index}\`,
            fixed: index === 1,
            fill: index === 9,
        } as ListViewColumnProps;
    });
}, []);

const generageItems = (length = 100) => {
    setItems(ArrayUtils.generateArray(length, (index) => {
        const value: {[key: string]: any} = {};
        for (let i = 0, il = columns.length; i < il; i++) {
            value[\`col\${i}\`] = \`\${NumberUtils.format(index * i)}\`;
        }
        return value;
    }));
};

useEffect(() => {
    generageItems();
}, []);

return <ListView fitToOuter="ffy" columns={columns} value={items} />`;
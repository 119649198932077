import DatetimeUtils from "@bizhermit/basic-utils/dist/datetime-utils";
import useTimer from "@bizhermit/react-sdk/dist/hooks/timer";
import { FC, useEffect, useState } from "react";
import TsCode from "../basic/ts-code";

const TimerDemoComponent: FC = () => {
  const [datetime, setDatetime] = useState(DatetimeUtils.format(new Date(), "yyyy/MM/dd hh:mm:ss"));
  const timer = useTimer({
    interval: 200,
    callback: () => {
      setDatetime(DatetimeUtils.format(new Date(), "yyyy/MM/dd hh:mm:ss"));
    }
  });
  useEffect(() => {
    timer.start();
  }, []);
  return (
    <>
    <TsCode value={`import useTimer from "@bizhermit/react-sdk/dist/hooks/timer";`} />
    <span>{datetime}</span>
    <TsCode value={code1} />
    </>
  );
};

export default TimerDemoComponent;

const code1 = 
`const [datetime, setDatetime] = useState(DatetimeUtils.format(new Date(), "yyyy/MM/dd hh:mm:ss"));

const timer = useTimer({
    interval: 200,
    callback: () => {
        setDatetime(DatetimeUtils.format(new Date(), "yyyy/MM/dd hh:mm:ss"));
    },
});

useEffect(() => {
    timer.start();
}, []);

return <span>{datetime}</span>;`;
import { useLayout } from "@bizhermit/react-sdk/dist/layouts/style";
import React, { ReactNode, useContext, useEffect, useMemo, useState, FC } from "react";
import { TitleContext } from "../menu-bar";
import Screen1 from "../../public/screen1.png";
import Screen2 from "../../public/screen2.png";
import Screen3 from "../../public/screen3.png";
import Screen4 from "../../public/screen4.png";
import ButtonDemoComponent from "../react-sdk-demo/button";
import CheckBoxDemoComponent from "../react-sdk-demo/checkbox";
import DateBoxDemoComponent from "../react-sdk-demo/datebox";
import ListViewDemoComponent from "../react-sdk-demo/listview";
import NumericBoxDemoComponent from "../react-sdk-demo/numericbox";
import RadioButtonDemoComponent from "../react-sdk-demo/radiobutton";
import SelectBoxDemoComponent from "../react-sdk-demo/selectbox";
import TextBoxDemoComponent from "../react-sdk-demo/textbox";
import TimerDemoComponent from "../react-sdk-demo/timer";
import TimeBoxDemoComponent from "../react-sdk-demo/timebox";
import TabContainerDemoComponent from "../react-sdk-demo/tab-container";
import SplitContainerDemoComponent from "../react-sdk-demo/split-container";
import ToggleBoxDemoComponent from "../react-sdk-demo/togglebox";
import SliderDemoComponent from "../react-sdk-demo/slider";
import MenuContainer from "@bizhermit/react-sdk/dist/containers/menu-container";
import Label from "@bizhermit/react-sdk/dist/texts/label";
import { MenuItemProps } from "@bizhermit/react-sdk/dist/controls/menu-list";
import AccordionContainer from "@bizhermit/react-sdk/dist/containers/accordion-container";
import { ColorRadioButton, DesignRadioButton } from "@bizhermit/react-sdk/dist/components/layout";
import Caption from "@bizhermit/react-sdk/dist/containers/caption";
import Row from "@bizhermit/react-sdk/dist/containers/row";
import FlexBox from "@bizhermit/react-sdk/dist/containers/flexbox";

const ReactSDKPage: FC = () => {
  const titleCtx = useContext(TitleContext);
  useEffect(() => {
    titleCtx.setTitle("ReactSDK DEMO");
  }, []);
  const layout = useLayout();

  const [content, setContent] = useState<{ node: ReactNode, title: string}>({ node: <SampleComponents />, title: "ScreenShot", });
  const menuItems = useMemo<Array<MenuItemProps>>(() => {
    const demoProps = (label: string, Component: FC) => {
      return {
        label,
        clicked: () => {
          setContent({ node: <Component />, title: label });
          return true;
        },
      };
    };
    return [
      demoProps("ScreenShot", SampleComponents),
      { label: "Hooks", caption: true },
      demoProps("Timer", TimerDemoComponent),
      { label: "Containers", caption: true },
      demoProps("TabContainer", TabContainerDemoComponent),
      demoProps("SplitContainer", SplitContainerDemoComponent),
      { label: "Control", caption: true },
      demoProps("Button", ButtonDemoComponent),
      demoProps("TextBox", TextBoxDemoComponent),
      demoProps("NumericBox", NumericBoxDemoComponent),
      demoProps("Slider", SliderDemoComponent),
      demoProps("DateBox", DateBoxDemoComponent),
      demoProps("TimeBox", TimeBoxDemoComponent),
      demoProps("SelectBox", SelectBoxDemoComponent),
      demoProps("CheckBox", CheckBoxDemoComponent),
      demoProps("ToggleBox", ToggleBoxDemoComponent),
      demoProps("RadioButton", RadioButtonDemoComponent),
      demoProps("ListView", ListViewDemoComponent),
    ];
  }, []);

  const isSmallScreenSize = layout.screenSize === "s";

  return (
    <>
    <MenuContainer fitToOuter="ffy"
      header={{ jsx: <Label type="h2" style={{ paddingLeft: 10 }}>{content.title}</Label> }}
      menu={{ items: menuItems, mode: isSmallScreenSize ? "closeToHeader" : "visible", width: 200 }}
    >
      <FlexBox fitToOuter="fill" scroll style={{ padding: 5 }}>
        <AccordionContainer caption="Layout" fitToOuter="fx" defaultOpened={!isSmallScreenSize}>
          <Row style={{ padding: 5 }}>
            <Caption label="Color" design direction="vertical" style={{ marginRight: 5}}>
              <ColorRadioButton unset />
            </Caption>
            <Caption label="Design" design direction="vertical">
              <DesignRadioButton unset />
            </Caption>
          </Row>
        </AccordionContainer>
        {content.node}
      </FlexBox>
    </MenuContainer>
    </>
  )
};

export default ReactSDKPage;

const SampleComponents: FC = () => {
  return (
    <>
    <img src={Screen1} alt="Image" className="screen" />
    <img src={Screen2} alt="Image" className="screen" />
    <img src={Screen3} alt="Image" className="screen" />
    <img src={Screen4} alt="Image" className="screen" />
    </>
  );
};
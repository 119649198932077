import CheckBox from "@bizhermit/react-sdk/dist/controls/checkbox";
import TsCode from "../basic/ts-code";
import { useState, FC } from "react";

const CheckBoxDemoComponent: FC = () => {
  const [value, setValue] = useState("0");
  return (
    <>
    <TsCode value={`import CheckBox from "@bizhermit/react-sdk/dist/controls/checkbox";`} />
    <CheckBox defaultValue={true} />
    <TsCode value={code1} />
    <CheckBox checkedValue="1" uncheckedValue="0" defaultValue={value} changed={v => setValue(v)}>CheckBox: {value}</CheckBox>
    <TsCode value={code2} />
    </>
  );
};

export default CheckBoxDemoComponent;

const code1 = 
`<CheckBox defaultValue={true} />`;

const code2 =
`const [value, setValue] = useState("0");

return <CheckBox checkedValue="1" uncheckedValue="0" defaultValue={value} changed={v => setValue(v)}>CheckBox: {value}</CheckBox>;`
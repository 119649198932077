import AccordionContainer from "@bizhermit/react-sdk/dist/containers/accordion-container";
import FlexBox from "@bizhermit/react-sdk/dist/containers/flexbox";
import Row from "@bizhermit/react-sdk/dist/containers/row";
import React, { useContext, useEffect, useState, FC } from "react";
import styled from "styled-components";
import Advertisement from "../advertisement";
import Anchor from "../basic/anchor";
import HR from "../basic/hr";
import { TitleContext } from "../menu-bar";
import TwitterIcon from "../../public/twitter.png";
import MailIcon from "../../public/mail.png";
import GitHubIcon from "../../public/github.png";
import GitHubLightIcon from "../../public/github_light.png";
import CoconalaIcon from "../../public/coconala.jpg";
import { useLayout } from "@bizhermit/react-sdk/dist/layouts/style";
import Button from "@bizhermit/react-sdk/dist/controls/button";

const IndexPage: FC = () => {
  const titleCtx = useContext(TitleContext);
  const layout = useLayout();
  useEffect(() => {
    titleCtx.setTitle("");
  }, []);

  const [accordionOpened, setAccordionOpened] = useState(true);
  useEffect(() => {
    setAccordionOpened(layout.screenSize !== "s");
  }, [layout.screenSize]);

  return (
    <>
    <FlexBox fitToOuter="fill" scroll style={{ padding: 5 }}>
      <Caption>Simple is Better.</Caption>
      <SubCaption>難しくしないこと</SubCaption>
      <SubCaption>それが効率化の王道です</SubCaption>
      <CaptionMessage>
        様々な現場を経験したフルスタックエンジニアとして、情報システムの構築を支援いたします。
      </CaptionMessage>
      <HR />
      <AccordionContainer caption="Skills" style={{ width: "100%" }} opened={accordionOpened}>
        <FlexBox fitToOuter="fill" style={{ padding: 5 }}>
          <SkillCaption>Language</SkillCaption>
          <ul>
            <li className="main">Typescript</li>
            <li>javascript</li>
            <li>Java</li>
            <li>C#</li>
            <li>VB.NET</li>
            <li>VBA</li>
            <li>VB6</li>
            <li>HTML5</li>
            <li>HTML Living Standard</li>
            <li>CSS3</li>
            <li>MarkDown</li>
          </ul>
          <SkillCaption>DataBase</SkillCaption>
          <ul>
            <li className="main">PostgreSQL</li>
            <li>MySQL</li>
            <li>Microsoft SQL Server</li>
            <li>Oracle SQL</li>
            <li>IBM i Series(AS/400)</li>
          </ul>
          <SkillCaption>Framework/Library</SkillCaption>
          <ul>
            <li>React</li>
            <li>Next.js</li>
            <li>Electron</li>
            <li>Express</li>
            <li>Spring Boot</li>
            <li>Struts2</li>
            <li><Anchor href="https://www.npmjs.com/~bizhermit">npm&#64;bizhermit</Anchor></li>
          </ul>
          <SkillCaption>Middleware</SkillCaption>
          <ul>
            <li>Apache</li>
            <li>Tomcat</li>
            <li>nginx</li>
          </ul>
          <SkillCaption>Operating System</SkillCaption>
          <ul>
            <li>Windows</li>
            <li>Windows Server</li>
            <li>Ubuntu</li>
            <li>CentOS</li>
            <li>Red Hat Enterprise Linux</li>
          </ul>
          <SkillCaption>Cloud</SkillCaption>
          <ul>
            <li>Microsoft Azure</li>
            <li>Google Cloud Platform</li>
          </ul>
          <SkillCaption>Editor</SkillCaption>
          <ul>
            <li>Visual Studio Code</li>
            <li>Visual Studio</li>
            <li>Android Studio</li>
            <li>Eclipse</li>
          </ul>
          <SkillCaption>Version Control System</SkillCaption>
          <ul>
            <li>GitHub</li>
            <li>GitLab</li>
            <li>Tortoise SVN</li>
          </ul>
          <SkillCaption>Communication Tool</SkillCaption>
          <ul>
            <li>Teams</li>
            <li>Slack</li>
            <li>Trello</li>
            <li>Zoom</li>
          </ul>
        </FlexBox>
      </AccordionContainer>
      <HR />
      <AccordionContainer caption="npm packages" style={{ width: "100%" }} opened={accordionOpened}>
        <FlexBox fitToOuter="fill" style={{ padding: 5 }}>
          <ul style={{ paddingLeft: 0 }}>
            <li>
              <Anchor className="npm" href="https://www.npmjs.com/package/@bizhermit/minifier">@bizhermit/minifier</Anchor>
              <span className="npm-ex">指定したディレクトリ内のjs、css、htmlファイルを圧縮するツール</span>
            </li>
            <li>
              <Anchor className="npm" href="https://www.npmjs.com/package/@bizhermit/starter">@bizhermit/starter</Anchor>
              <span className="npm-ex">Typescriptプロジェクトのひな型を作るツール</span>
            </li>
            <li>
              <Anchor className="npm" href="https://www.npmjs.com/package/@bizhermit/basic-utils">@bizhermit/basic-utils</Anchor>
              <span className="npm-ex">String/Number/Date/Arrayの便利メソッド群</span>
            </li>
            <li>
              <Anchor className="npm" href="https://www.npmjs.com/package/@bizhermit/time">@bizhermit/time</Anchor>
              <span className="npm-ex">Timeオブジェクト</span>
            </li>
            <li>
              <Anchor className="npm" href="https://www.npmjs.com/package/@bizhermit/nextron">@bizhermit/nextron</Anchor>
              <span className="npm-ex">Windows/Mac/Linux向けデスクトップアプリケーションの基盤（Next.js×Electron）</span>
            </li>
            <li>
              <Anchor className="npm" href="https://www.npmjs.com/package/@bizhermit/nexpress">@bizhermit/nexpress</Anchor>
              <span className="npm-ex">Webアプリアプリケーションの基盤（Next.js×Express）</span>
            </li>
            <li>
              <Anchor className="npm" href="https://www.npmjs.com/package/@bizhermit/react-sdk">@bizhermit/react-sdk</Anchor>
              <Row>
                <span className="npm-ex" style={{ marginRight: 15 }}>Reactのコンポーネント群</span>
                <Anchor href="/react-sdk">demo</Anchor>
              </Row>
            </li>
            <li>
              <Anchor className="npm" href="https://www.npmjs.com/package/@bizhermit/license">@bizhermit/license</Anchor>
              <span className="npm-ex">依存パッケージ内に認知外ライセンスが含まれているかどうか検知するツール</span>
            </li>
            <li>
              <Anchor className="npm" href="https://www.npmjs.com/package/@bizhermit/cli-sdk">@bizhermit/cli-sdk</Anchor>
              <span className="npm-ex">CLIアプリ開発時の便利メソッド群</span>
            </li>
          </ul>
        </FlexBox>
      </AccordionContainer>
      <HR />
      <FlexBox row style={{ width: "100%", justifyContent: layout.screenSize === "s" ? "space-around" : "flex-start", flexWrap: "wrap" }}>
        <Anchor href="https://github.com/bizhermit">
          <Button style={{ height: 90 }}>
            <FlexBox center className="icon-link">
              <img className="link-icon" src={layout.color === "dark" ? GitHubLightIcon : GitHubIcon} alt="Image" />
              <span className="link-text">bizhermit</span>
            </FlexBox>
          </Button>
        </Anchor>
        <Anchor href="https://twitter.com/rs_hermit">
          <Button style={{ height: 90 }}>
            <FlexBox center className="icon-link">
              <img className="link-icon" src={TwitterIcon} alt="Image" />
              <span className="link-text">&#64;rs_hermit</span>
            </FlexBox>
          </Button>
        </Anchor>
        <Anchor href="https://coconala.com/users/3043897">
          <Button style={{ height: 90 }}>
              <FlexBox center className="icon-link">
                <img className="link-icon" src={CoconalaIcon} alt="Image" />
                <span className="link-text">coconala</span>
              </FlexBox>
            </Button>
        </Anchor>
        <Anchor href="mailto:support&#64;bizhermit.com">
          <Button style={{ height: 90 }}>
            <FlexBox center className="icon-link">
              <img className="link-icon" src={MailIcon} alt="Image" />
              <span className="link-text">support&#64;bizhermit.com</span>
            </FlexBox>
          </Button>
        </Anchor>
      </FlexBox>
      <HR />
      <Advertisement>{`
      <a href="https://px.a8.net/svt/ejp?a8mat=3BBR4S+20MXCQ+50+2HK0TD" rel="nofollow">
      <img border="0" width="350" height="80" alt="" src="https://www26.a8.net/svt/bgt?aid=200422684122&wid=002&eno=01&mid=s00000000018015042000&mc=1"></a>
      <img border="0" width="1" height="1" src="https://www15.a8.net/0.gif?a8mat=3BBR4S+20MXCQ+50+2HK0TD" alt="">
      `}</Advertisement>
      <Advertisement>{`
      <a href="https://px.a8.net/svt/ejp?a8mat=3HR5CD+4ASQU2+CO4+15SUDT" rel="nofollow">
      <img border="0" width="350" height="80" alt="" src="https://www20.a8.net/svt/bgt?aid=211218637260&wid=002&eno=01&mid=s00000001642007021000&mc=1"></a>
      <img border="0" width="1" height="1" src="https://www11.a8.net/0.gif?a8mat=3HR5CD+4ASQU2+CO4+15SUDT" alt="">
      `}</Advertisement>
      <Advertisement>{`
      <a href="https://px.a8.net/svt/ejp?a8mat=3BDZYA+F0LM2A+CO4+6KESX" rel="nofollow">
      <img border="0" width="350" height="80" alt="" src="https://www25.a8.net/svt/bgt?aid=200527426908&wid=001&eno=01&mid=s00000001642001103000&mc=1"></a>
      <img border="0" width="1" height="1" src="https://www11.a8.net/0.gif?a8mat=3BDZYA+F0LM2A+CO4+6KESX" alt="">
      `}</Advertisement>
      <Advertisement>{`
      <a href="https://px.a8.net/svt/ejp?a8mat=3BDZYA+G9UK1E+348+1C6TPD" rel="nofollow">
      <img border="0" width="350" height="292" alt="" src="https://www27.a8.net/svt/bgt?aid=200527426984&wid=001&eno=01&mid=s00000000404008094000&mc=1"></a>
      <img border="0" width="1" height="1" src="https://www10.a8.net/0.gif?a8mat=3BDZYA+G9UK1E+348+1C6TPD" alt="">
      `}</Advertisement>
      <Advertisement>{`
      <a href="https://px.a8.net/svt/ejp?a8mat=3BDZYA+GB1F8Y+348+6KU8H" rel="nofollow">
      <img border="0" width="350" height="292" alt="" src="https://www27.a8.net/svt/bgt?aid=200527426986&wid=001&eno=01&mid=s00000000404001105000&mc=1"></a>
      <img border="0" width="1" height="1" src="https://www18.a8.net/0.gif?a8mat=3BDZYA+GB1F8Y+348+6KU8H" alt="">
      `}</Advertisement>
    </FlexBox>
    </>
  );
};

export default IndexPage;

const Caption = styled.h2`
width: 100%;
font-style: italic;
`;
const SubCaption = styled.p`
width: 100%;
margin: 0px;
padding: 0px 20px;
`;

const CaptionMessage = styled.p`
width: 100%;
margin-top: 50px;
`;

const SkillCaption = styled.span`
margin: 5px;
white-space: nowrap;
opacity: 0.7;
`;
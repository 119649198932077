import ArrayUtils from "@bizhermit/basic-utils/dist/array-utils";
import RadioButton from "@bizhermit/react-sdk/dist/controls/radiobutton";
import { useCallback, FC } from "react";
import TsCode from "../basic/ts-code";

const RadioButtonDemoComponent: FC = () => {
  const source = useCallback(() => {
    return new Promise<Array<{ value: number; label: string; }>>((resolve) => {
      const items = ArrayUtils.generateArray(5, (index) => {
        return { value: index, label: `item ${index}` };
      });
      setTimeout(() => {
        resolve(items)
      }, 1000);
    })
  }, []);

  return (
    <>
    <TsCode value={`import RadioButton from "@bizhermit/react-sdk/dist/controls/radiobutton";`} />
    <RadioButton source={source} defaultValue={3} wrap={true} />
    <TsCode value={code1} />
    </>
  );
};

export default RadioButtonDemoComponent;

const code1 = 
`const source = useCallback(() => {
    return new Promise<Array<{ value: number; label: string; }>>((resolve) => {
        const items = ArrayUtils.generateArray(5, (index) => {
            return { value: index, label: \`item \${index}\` };
        });
        setTimeout(() => {
            resolve(items)
        }, 1000);
    });
}, []);

return <RadioButton source={source} defaultValue={3} wrap={true} />;`;
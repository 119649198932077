import { FC, useEffect, useRef } from "react";
import styled from "styled-components";

const AdvertisementWrap = styled.div`
max-width: 100%;
overflow: hidden;
flex: none;
`;

const Advertisement: FC<{ children: string; }> = ({ children }) => {
  const ref = useRef<HTMLDivElement>();
  useEffect(() => {
    ref.current.innerHTML = children;
    ref.current.querySelectorAll("a").forEach((elem) => {
      elem.target = "_blank";
      elem.rel = [elem.rel, "noopener", "noreferrer"].join(" ");
    });
  }, []);
  return (
    <AdvertisementWrap ref={ref} />
  );
};

export default Advertisement;
import AccordionContainer from "@bizhermit/react-sdk/dist/containers/accordion-container";
import SplitContainer from "@bizhermit/react-sdk/dist/containers/split-container";
import { FC } from "react";
import TsCode from "../basic/ts-code";

const SplitContainerDemoComponent: FC = () => {
  return (
    <>
    <AccordionContainer caption="code" style={{ width: "100%", maxHeight: "50%" }} opened={false}>
      <TsCode value={`import SplitContainer from "@bizhermit/react-sdk/dist/containers/split-container";`} />
      <TsCode value={code1} />
    </AccordionContainer>
    <SplitContainer
      fitToOuter="ffy"
      content1={{
        component: () => <><h1>Split Content 1</h1></>,
      }}
      content2={{
        component: () => <SplitContainer fitToOuter="fill" direction="vertical"
          content1={{
            component: () => <><h1>Split Content 2</h1></>
          }}
          content2={{
            component: () => <><h1>Split Content 3</h1></>
          }}
        />,
      }}
    />
    </>
  );
};

export default SplitContainerDemoComponent;

const code1 = 
`<SplitContainer
    fitToOuter="ffy"
    content1={{
        component: () => <><h1>Split Content 1</h1></>,
    }}
    content2={{
        component: () => <SplitContainer fitToOuter="fill" direction="vertical"
            content1={{
                component: () => <><h1>Split Content 2</h1></>
            }}
            content2={{
                component: () => <><h1>Split Content 3</h1></>
            }}
        />,
    }}
/>`;
import AccordionContainer from "@bizhermit/react-sdk/dist/containers/accordion-container";
import TabContainer from "@bizhermit/react-sdk/dist/containers/tab-container";
import { FC } from "react";
import TsCode from "../basic/ts-code";

const TabContainerDemoComponent: FC = () => {
  return (
    <>
    <AccordionContainer caption="code" style={{ width: "100%", maxHeight: "50%" }} opened={false}>
      <TsCode value={`import TabContainer from "@bizhermit/react-sdk/dist/containers/tab-container";`} />
      <TsCode value={code1} />
    </AccordionContainer>
    <TabContainer
      fitToOuter="ffy"
      tabFill={true}
      contents={[{
        code: "tab1",
        title: "Tab1",
        component: () => <><h1>Tab Content 1</h1></>,
      }, {
        code: "tab2",
        title: "Tab2",
        component: () => <><h1>Tab Content 2</h1></>,
      }, {
        code: "tab3",
        title: "Tab3",
        component: () => <><h1>Tab Content 3</h1></>,
      }, {
        code: "tab4",
        title: "Tab4",
        component: () => <><h1>Tab Content 4</h1></>,
      }]}
    />
    </>
  );
};

export default TabContainerDemoComponent;

const code1 = 
`<TabContainer
    fitToOuter="ffy"
    tabFill={true}
    contents={[{
        code: "tab1",
        title: "Tab1",
        component: () => <><h1>Tab Content 1</h1></>,
    }, {
        code: "tab2",
        title: "Tab2",
        component: () => <><h1>Tab Content 2</h1></>,
    }, {
        code: "tab3",
        title: "Tab3",
        component: () => <><h1>Tab Content 3</h1></>,
    }, {
        code: "tab4",
        title: "Tab4",
        component: () => <><h1>Tab Content 4</h1></>,
    }]}
/>`;
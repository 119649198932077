import Slider from "@bizhermit/react-sdk/dist/controls/slider";
import { FC, useState } from "react";
import TsCode from "../basic/ts-code";

const SliderDemoComponent: FC = () => {
  const [value, setValue] = useState(10);
  return (
    <>
    <TsCode value={`import Slider from "@bizhermit/react-sdk/dist/controls/slider";`} />
    <Slider defaultValue={50} />
    <TsCode value={code1} />
    <Slider defaultValue={value} changed={v => setValue(v)}  />
    <TsCode value={code2} />
    </>
  );
};

export default SliderDemoComponent;

const code1 = 
`<Slider defaultValue={50} />`;

const code2 =
`const [value, setValue] = useState(10);

return <Slider defaultValue={value} changed={v => setValue(v)}  />;`
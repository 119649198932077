import ArrayUtils from "@bizhermit/basic-utils/dist/array-utils";
import SelectBox from "@bizhermit/react-sdk/dist/controls/selectbox";
import { useCallback, FC } from "react";
import TsCode from "../basic/ts-code";

const SelectBoxDemoComponent: FC = () => {
  const source = useCallback(() => {
    return new Promise<Array<{ value: number; label: string; }>>((resolve) => {
      const items = ArrayUtils.generateArray(20, (index) => {
        return { value: index, label: `item ${index}` };
      });
      setTimeout(() => {
        resolve(items)
      }, 1000);
    })
  }, []);

  return (
    <>
    <TsCode value={`import SelectBox from "@bizhermit/react-sdk/dist/controls/selectbox";`} />
    <SelectBox source={source} defaultValue={5} appendEmptyItem={true} />
    <TsCode value={code1} />
    </>
  );
};

export default SelectBoxDemoComponent;

const code1 = 
`const source = useCallback(() => {
    return new Promise<Array<{ value: number; label: string; }>>((resolve) => {
        const items = ArrayUtils.generateArray(20, (index) => {
            return { value: index, label: \`item \${index}\` };
        });
        setTimeout(() => {
            resolve(items)
        }, 1000);
    });
}, []);

return <SelectBox source={source} defaultValue={5} appendEmptyItem={true} />;`;
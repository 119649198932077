import TimeBox from "@bizhermit/react-sdk/dist/controls/timebox";
import Time from "@bizhermit/time";
import { FC } from "react";
import TsCode from "../basic/ts-code";

const TimeBoxDemoComponent: FC = () => {
  console.log(new Time(new Date()).format());
  return (
    <>
    <TsCode value={`import TimeBox from "@bizhermit/react-sdk/dist/controls/timebox";`} />
    <TimeBox defaultValue={300} mode="hm" />
    <TsCode value={code1} />
    <TimeBox defaultValue={1200000} mode="hm" unit="millisecond" minuteInterval={1} minuteIncrementInterval={5} pulldownButton={false} />
    <TsCode value={code2} />
    <TimeBox defaultValue={1800} mode="hms" />
    <TsCode value={code3} />
    </>
  );
};

export default TimeBoxDemoComponent;

const code1 = 
`<TimeBox defaultValue={300} mode="hm" />`;

const code2 = 
`<TimeBox
    defaultValue={1200000}
    mode="hm"
    unit="millisecond"
    minuteInterval={1}
    minuteIncrementInterval={5}
    pulldownButton={false}
/>`;

const code3 = 
`<TimeBox defaultValue={1800} mode="hms" />`;
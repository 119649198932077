import Row from "@bizhermit/react-sdk/dist/containers/row";
import Button from "@bizhermit/react-sdk/dist/controls/button";
import TextBox from "@bizhermit/react-sdk/dist/controls/textbox";
import Label from "@bizhermit/react-sdk/dist/texts/label";
import { FC, useState } from "react";
import TsCode from "../basic/ts-code";

const TextBoxDemoComponent: FC = () => {
  const [params, setParams] = useState({});
  const [paramsStr, setParamsStr] = useState("");
  return (
    <>
    <TsCode value={`import TextBox from "@bizhermit/react-sdk/dist/controls/textbox";`} />
    <TextBox defaultValue="textbox" />
    <TsCode value={code1} />
    <Row>
      <TextBox name="value1" bind={params} changed={() => {
        setParamsStr(JSON.stringify(params));
      }} />
      <Button click={() => setParams({})}>Clear</Button>
      <Label>{paramsStr}</Label>
    </Row>
    <TsCode value={code2} />
    </>
  );
};

export default TextBoxDemoComponent;

const code1 = 
`<TextBox defaultValue="textbox" />`;

const code2 =
`const [params, setParams] = useState({});
const [paramsStr, setParamsStr] = useState("");

return (<>
    <TextBox name="value1" bind={params} changed={() => {
        setParamsStr(JSON.stringify(params));
    }} />
    <Button click={() => setParams({})}>Clear</Button>
    <Label>{paramsStr}</Label>
</>);`